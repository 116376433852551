import { MyWorker } from "./MyWorker";

let version = 1;

let apiStatusGood = true, upgradeModeSet = null;
export const startVersionUpdateListener = function () {
  const versionUpdateListener = new MyWorker('VersionUpdateListener', `${window.location.origin}/scripts/VersionUpdateListner.js`, window, function (status, worker) {
    let data = status;
    if (typeof status === 'object') {
      status = status.type;
    }
    switch (status) {
      case "good":
        if (!apiStatusGood) {
          if (upgradeModeSet) {
            worker.terminate();
            window.location.reload();
          }
          console.log('ShopDot upgrade complete');
          apiStatusGood = true;
        }
        let lastActiveTime = Date.now();
        break;
      case "maintenance":
      case "upgrading":
        apiStatusGood = false;
        if (!upgradeModeSet) {
          if (status === "upgrading") {
            console.log("ShopDot is currently being updated");
            console.log("Thank you for your patience. When the update is complete this page will automatically refresh.");
          } else if (status === "maintenance") {
            console.log("ShopDot will be back soon");
            console.log("Thank you for your patience. When the maintenance is complete this page will automatically refresh.");
          }
          upgradeModeSet = true;
        }
        break;
      case "reloadPage":
        worker.terminate();
        window.location.reload();
        break;
      case "buildDetails":
        // handle change in build details to do optional or mandatory upgrades here
        // if (data && typeof data === 'object') {
        // e.g. data.t, data.ver, etc
        // }
        break;
      case "possibleSystemDown":
        // ui is unreachable
        console.log('Unable to reach app. Service might be temporarily unavailable.');
        break;
      case "reloadPageRequired":
        window.location.reload();
        break;
      case "parseError":
        // TODO: future improvement
        break;
      default:
        console.log(`Unhandled status: ${status}`);
    }
  });
  versionUpdateListener.postMessage('start');
};
// console.log('HELLLLOOOO-------------------------->')
// startVersionUpdateListener();
